// Display units for the user to choose from
const DisplayUnits =  [
	{ name: 'File units', type: '' },
	{ name: 'Millimeters', type: 'mm' },
	{ name: 'Centimeters', type: 'cm' },
	{ name: 'Meters', type: 'm' },
	{ name: 'Inches', type: 'in' },
	{ name: 'Feet', type: 'ft' },
	{ name: 'Feet and fractional inches', type: 'ft-and-fractional-in' },
	{ name: 'Feet and decimal inches', type: 'ft-and-decimal-in' },
	{ name: 'Decimal inches', type: 'decimal-in' },
	{ name: 'Decimal feet', type: 'decimal-ft' },
	{ name: 'Fractional inches', type: 'fractional-in' },
	{ name: 'Meters and centimeters', type: 'm-and-cm' },
	{ name: 'Points', type: 'pt' },
];

const Precision = [
  { name: 'File precision', value: '' },
  { name: '0 (1)', value: 0 },
  { name: '0.1 (1/2)', value: 1 },
  { name: '0.01 (1/4)', value: 2 },
  { name: '0.001 (1/8)', value: 3 },
  { name: '0.0001 (1/16)', value: 4 },
	{ name: '0.00001 (1/32)', value: 5 },
	{ name: '0.000001 (1/64)', value: 6 }
];

export const displayUnits = DisplayUnits.map(x => x.name);
export const displayUnitsEnum = DisplayUnits.map(x => x.type);

export const displayUnitsPrecision = Precision.map(x => x.name);
export const displayUnitsPrecisionEnum = Precision.map(x => x.value);